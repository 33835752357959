import React from "react";
import { NavLink } from "react-router-dom";
import "./logo.css";

function Logo() {
  return (
    <NavLink to="/dashboard" className="logo-area">
      <img className="group-134" src="/img/logo.svg" />
      <img className="vector-3" src="/img/vector-3@2x.svg" />
      <div className="logo-text poppins-black-white-20px">
        <div className="sustainability-text">SUSTAINABILITY</div>
        <div className="fishing-text">FISHING</div>
      </div>
    </NavLink>
  );
}

export default Logo;
